<template>
    <div>
        <b-card no-body>
            <b-row>
                <b-card-body class="pt-0 d-lg-flex d-none px-1">
                    <b-col cols="12">
                        <div class="d-flex justify-content-center align-items-center pt-2" style="gap: 15px">
                            <label class="text-center d-flex justify-content-center align-items-center"
                                style="font-weight: 400; font-size: 18px">فیلتر</label>
                            <b-col>
                                <v-select dir="rtl" v-model="filters.status" :options="commentsOptions"
                                    :reduce="(item) => item.value" placeholder="وضعیت">
                                    <span slot="no-options">موردی یافت نشد.</span>
                                </v-select>
                            </b-col>
                            <b-button class="d-flex justify-content-center align-items-center" variant="primary"
                                type="submit" :disabled="isLoadingTwo" @click="callGetAllCommentsFiltered()">
                                <span class="text-nowrap" style="font-weight: 700; font-size: 16px; width: 160px">اعمال
                                    فیلتر ها</span>
                                <b-spinner v-show="isLoadingTwo" class="position-absolute" small type="grow" />
                            </b-button>
                        </div>
                    </b-col>
                </b-card-body>
            </b-row>
            <!-- mobile -->
            <b-card-body class="pt-0 d-flex flex-column px-1 d-lg-none">
                <b-col class="d-flex justify-content-between align-items-center pt-2" style="gap: 24px">
                    <b-col cols="2">
                        <label class="text-right w-100 d-flex justify-content-start align-items-center p-0"
                            style="font-weight: 400; font-size: 18px; color: #6d6d6d">فیلتر</label>
                    </b-col>
                    <b-col cols="5" class="p-0">
                        <b-button class="d-flex w-100 justify-content-center align-items-center border-radius-25"
                            variant="primary" style="max-height: 32px" type="submit" :disabled="isLoadingTwo"
                            @click="callGetAllCommentsFiltered()">
                            <span class="text-nowrap" style="font-weight: 700; font-size: 16px; padding: 2px 0">اعمال
                                فیلتر ها</span>
                            <b-spinner v-show="isLoadingTwo" class="position-absolute" small type="grow" />
                        </b-button>
                    </b-col>
                </b-col>
                <b-col>
                    <v-select dir="rtl" v-model="filters.status" :options="commentsOptions"
                        :reduce="(item) => item.value" placeholder="وضعیت" style="margin-top: 11px">
                        <span slot="no-options">موردی یافت نشد.</span>
                    </v-select>
                </b-col>
            </b-card-body>

            <div>
                <LoadingComponent v-if="isLoadingTwo" />
                <template v-else>
                    <b-col class="pt-1 pb-1" v-if="massagesList.length > 0" style="width: 100%; overflow: visible">
                        <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="massagesList"
                            :rtl="false" class="w-100">
                            <div slot="emptystate" class="w-100 text-center">
                                موردی یافت نشد
                            </div>
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: post_title -->
                                <span v-if="props.column.field === 'id'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.id }}</span>
                                </span>
                                <span v-if="props.column.field === 'full_name'" class="text-nowrap">
                                    <span class="text-nowrap">
                                        {{
                                        props.row.full_name.length > 40
                                        ? props.row.full_name.substring(0, 40) + "..."
                                        : !props.row.full_name
                                        ? "-"
                                        : props.row.full_name
                                        }}
                                    </span>
                                </span>
                                <span v-if="props.column.field === 'email'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.email }}</span>
                                </span>
                                <span v-if="props.column.field === 'response'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.response == null ? "-" : props.row.response }}</span>
                                </span>
                                <span v-if="props.column.field === 'ip'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.ip }}</span>
                                </span>
                                <span v-if="props.column.field === 'message'" class="text-nowrap">
                                    <span class="text-nowrap">{{
                                        props.row.message.length > 40
                                        ? props.row.message.substring(0, 40) + "..."
                                        : !props.row.message
                                        ? "-"
                                        : props.row.message
                                        }}</span>
                                </span>
                                <span v-if="props.column.field === 'status'" class="text-nowrap">
                                    <b-badge :variant="props.row.status == 0
                                        ? 'secondary'
                                        : props.row.status == 1
                                            ? 'success'
                                            : props.row.status == 2
                                                ? 'danger'
                                                : ''
                                    ">
                                        {{ props.row.status == 0 ? "در انتظار تایید" : "" }}
                                        {{ props.row.status == 1 ? "تایید شده" : "" }}
                                        {{ props.row.status == 2 ? "رد شده" : "" }}
                                    </b-badge>
                                </span>
                                <span v-if="props.column.field === 'created_at'" class="text-nowrap">
                                    <span class="text-nowrap">{{
                                        props.row.created_at ? props.row.created_at : "-"
                                        }}</span>
                                </span>
                                <!-- Column: Action -->
                                <span v-else-if="props.column.field === 'action'" style="width: 100px !important"
                                    v-b-modal.modal-prevent-closing @click="getCommentView(props.row.id)">
                                    <span>
                                        <b-link class="w-100 d-flex justify-content-center">
                                            <feather-icon icon="MoreVerticalIcon" size="16"
                                                class="text-body align-middle mr-25" />
                                        </b-link>
                                    </span>
                                </span>
                            </template>
                        </vue-good-table>
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalComments" :per-page="perPage" first-number
                            last-number prev-class="prev-item" next-class="next-item"
                            class="mt-1 mb-0 p-0 d-flex align-content-center" dir="ltr">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </template>
            </div>
        </b-card>
        <!-- modal edit -->
        <b-modal id="modal-prevent-closing" ref="modal" centered size="lg">
            <LoadingComponent v-if="isLoading" />
            <form ref="form" @submit.prevent="" v-else>
                <h3 style="
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              text-align: center;
            ">
                    عنوان پست
                </h3>
                <b-row>
                    <b-col class="pt-3 d-flex justify-content-between">
                        <h6 style="font-size: 20px; font-weight: 500">
                            {{ userData.author }}
                        </h6>
                        <p>{{ userData.date }}</p>
                        <p>{{ userData.time }}</p>
                    </b-col>
                </b-row>
                <b-row class="d-flex flex-column">
                    <b-col>
                        <b-form-textarea id="textarea-plaintext" disabled no-resize rows="4" style="
                  border-radius: 5px;
                  padding-left: 10px;
                  direction: ltr;
                  font-size: 18px;
                  font-weight: 500;
                " :value="userData.agent" />
                    </b-col>
                    <b-col class="pt-1">
                        <b-row>
                            <b-col md="6">
                                <b-form-group>
                                    <b-form-input disabled :value="userData.author_ip" style="
                        border: 0;
                        border-radius: 5px;
                        padding-left: 10px;
                        direction: ltr;
                        font-size: 18px;
                        font-weight: 500;
                      " />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-input disabled :value="userData.author_email" style="
                      border: 0;
                      border-radius: 5px;
                      padding-left: 10px;
                      direction: ltr;
                      font-size: 18px;
                      font-weight: 500;
                    " />
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- <b-col>
              <b-form-group>
                <b-form-input
                  disabled
                  :value="userData.author_url"
                  style="
                    border: 0;
                    border-radius: 5px;
                    padding-left: 10px;
                    direction: ltr;
                    font-size: 18px;
                    font-weight: 500;
                  "
                />
              </b-form-group>
            </b-col> -->
                    <b-col>
                        <p class="pt-2" style="font-size: 20px; line-height: normal">
                            {{ userData.content }}
                        </p>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <b-form-textarea id="textarea-plaintext" no-resize rows="4" style="
                    border-radius: 5px;
                    padding-left: 10px;
                    font-size: 18px;
                    font-weight: 500;
                  " placeholder="پاسخ دهید" v-model="formData.content" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6">
                        <button @click="updateCommentTrash(userData.id)" variant="secondary" pill
                            class="btn btn-secondary w-100 mt-1" style="
                  height: 36px;
                  font-weight: 700;
                  font-family: 16px;
                  border-radius: 25px;
                ">
                            رد کردن
                        </button>
                    </b-col>
                    <b-col md="6">
                        <button @click="replayComment(userData.id)" pill class="btn btn-primary w-100 mt-1" style="
                  height: 36px;
                  font-weight: 700;
                  font-family: 16px;
                  background: #d8d6de;
                  border-radius: 25px;
                ">
                            انتشار
                        </button>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </div>
</template>
<script>
import debounce from "lodash/debounce";
import BCardCode from "@core/components/b-card-code";
import LoadingComponent from "@/components/LoadingComponent";
import axios from "axios";

import {
    BModal,
    BCard,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        VueGoodTable,
        ToastificationContent,
        BCardCode,
        BButton,
        BRow,
        BCol,
        BSpinner,
        BFormInput,
        BFormTextarea,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormCheckbox,
        vSelect,
        LoadingComponent,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            isLoading: false,
            isLoadingTwo: false,
            perPage: 15,
            totalComments: 0,
            currentPage: 1,
            massageTray: [],
            massagesList: [],
            columns: [
                {
                    label: "شناسه",
                    field: "id",
                },
                {
                    label: "نام و نام خانوادگی",
                    field: "full_name",
                },
                {
                    label: "ایمیل",
                    field: "email",
                },
                {
                    label: "پیام",
                    field: "message",
                },
                {
                    label: "پاسخ",
                    field: "response",
                },
                {
                    label: "وضعیت",
                    field: "status",
                },
                {
                    label: "ip",
                    field: "ip",
                },
                {
                    label: "زمان ارسال",
                    field: "created_at",
                },
                {
                    label: "عملیات",
                    field: "action",
                },
            ],
            filters: {
                status: null,
            },
            commentsOptions: [
                { value: 0, label: "در انتظار تایید" },
                { value: 1, label: "تایید شده" },
                { value: 2, label: "رد شده" },
            ],
            formData: {
                content: "",
            },
            userData: {
                id: null,
                post_id: null,
                agent: "",
                author: "",
                author_email: "",
                author_ip: "",
                author_url: "",
                content: "",
                date: "",
                time: "",
            },
        };
    },
    methods: {
        getAllContactUs() {
            this.isLoadingTwo = true;
            // let requestBody = {
            //     params: { page: this.currentPage },
            // };

            // if (this.filters.status == 0 || this.filters.status == 1 || this.filters.status == 2) {
            //     if (this.filters.status != null) {
            //         requestBody.params.status = this.filters.status;
            //     } else if (this.filters.status == null || this.filters.status == "") {
            //         delete requestBody.params.status;
            //     }
            // }

            axios
                .get("/api/v1/admin/contact-us")
                .then((response) => {
                    this.totalComments = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.massageTray = response.data.data.data;
                    this.massagesList = [];
                    this.massageTray.forEach((item) => {
                        this.massagesList.push({
                            id: item.id,
                            full_name: item.full_name,
                            email: item.email,
                            message: item.message,
                            response: item.response,
                            status: item.status,
                            ip: item.ip,
                            agent: item.agent,
                            created_at: item.created_at,
                            updated_at: item.updated_at,
                        });
                    });
                    this.isLoadingTwo = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoadingTwo = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در لیست پیام ها پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        getCommentView(id) {
            this.isLoading = true;
            axios
                .get(`/api/v1/admin/comments/${id}/view`)
                .then((response) => {
                    let res = response.data.data;
                    this.userData.id = res.id;
                    this.userData.post_id = res.post_id;
                    this.userData.agent = res.agent;
                    this.userData.author = res.author;
                    this.userData.author_email = res.author_email;
                    this.userData.author_ip = res.author_ip;
                    this.userData.author_url = res.author_url;
                    this.userData.content = res.content;
                    this.userData.date = res.date;
                    this.userData.time = res.time;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در لیست پیام ها پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        updateCommentTrash(id) {
            this.isLoadingTwo = true;
            axios
                .post(`/api/v1/admin/comments/${id}/update`, { status: 2 })
                .then((response) => {
                    this.formData.content = "";
                    this.getAllContactUs();
                    this.$refs["modal"].hide();
                    this.isLoadingTwo = false;
                })
                .catch((error) => {
                    this.isLoadingTwo = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در لیست پیام ها پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        replayComment(id) {
            let username = localStorage.getItem("user_data");
            this.isLoadingTwo = true;
            this.formData.post_id = this.userData.post_id;
            this.formData.parent_id = this.userData.id;
            this.formData.author = username;
            this.formData.author_email = "info.rcgroup@gamil.com";

            if (this.formData.content) {
                axios
                    .post("/api/v1/admin/comments/reply", this.formData)
                    .then((response) => {
                        this.formData.content = "";
                    })
                    .catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "خطا",
                                icon: "XOctagonIcon",
                                text: "مشکلی در لیست پیام ها پیش آمده.",
                                variant: "danger",
                            },
                        });
                    });
            }

            axios
                .post(`/api/v1/admin/comments/${id}/update`, { status: 1 })
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "دیدگاه تایید شد",
                            icon: "XOctagonIcon",
                            text: "دیدگاه با موفقیت تایید شد.",
                            variant: "success",
                        },
                    });
                    this.getAllContactUs();
                    this.$refs["modal"].hide();
                    this.isLoadingTwo = false;
                })
                .catch((error) => {
                    this.isLoadingTwo = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در لیست پیام ها پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        callGetAllCommentsFiltered() {
            if (this.currentPage == 1) {
                this.getAllContactUs();
            } else {
                this.currentPage = 1;
            }
        },
    },

    mounted() {
        this.getAllContactUs();
        this.$watch("currentPage", (newCurrentPage) => {
            this.getAllContactUs();
        });
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.filter-row {
    justify-content: space-between;
}

.modal .modal-header .close {
    margin: 0%;
    position: relative;
    left: 0%;
    /* transform: translate(200px, 0px); */
}

@media (max-width: 400px) {
    .modal .modal-header .close {
        left: 0%;
    }
}

.modal .modal-header .close {
    padding: 0.01rem 0.62rem;
    box-shadow: 0 0px 0px 0 rgba(34, 41, 47, 0.1);
    border-radius: 100%;
    z-index: 1;
}

.modal .modal-header {
    display: flex;
    flex-direction: row-reverse;
}

.modal-header .close {
    margin: -0.3rem 0rem;
}

.close {
    float: right !important;
}

button {
    border-radius: 0;
}

.close {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: #5e5873;
    opacity: 0.5;
}

button,
select {
    text-transform: none;
}

button,
input {
    overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.modal .modal-header {
    display: none;
}

.modal .modal-footer {
    display: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
</style>